export function capitalize(word : string) {
    return word
            .charAt(0)
            .toUpperCase() + word.slice(1);
}

export function classStyle(classname : string) {
    return classname
            .toLowerCase()
            .replace(' ', '_');
}

export function capAndStrip(word : string) {
    return word
            .charAt(0)
            .toUpperCase() 
            + word
                .slice(1)
                .replace('_', ' ');
}