import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { LoadingListProps } from "../../types/propTypes"
import { ListItem } from "./ListItem"
import styles from './LoadingList.module.scss'

export function LoadingList({length, loading, content, type} : LoadingListProps) : JSX.Element {
    if (length > 0 && !loading) {
        return (
        <>
            {content.map((c) => (
                <ListItem 
                    media={c} 
                    key={c.title} 
                />
                )) 
            }
        </>)
    }
    else if (loading) {
        return (<><FontAwesomeIcon className={styles["spinner"]} icon={faSpinner} /></>)
    }
    else {
        return (<h3>No {type}s found! ;-;</h3>)
    }
}
