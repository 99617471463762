import { useState } from "react";
import { EditPopupProps } from "../../../types/propTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import styles from './EditPopup.module.scss';

export function EditPopup ({media, setEditMode, updateData} : EditPopupProps) {
    const [status, setStatus] = useState<string>(media.status);

    const [image, setImage] = useState(media.image);
    const [title, setTitle] = useState(media.title);
    const [link, setLink] = useState(media.link);

    const [owned, setOwned] = useState(media.owned);
    const [wishlist, setWishlist] = useState(media.wishlist);

    const [season, setSeason] = useState(media.season);
    const [episode, setEpisode] = useState(media.episode);
    const [chapter, setChapter] = useState(media.chapter);

    const [platform, setPlatform] = useState(media.platform);
    const [description, setDescription] = useState(media.description);

    function update() {
        let updatedMedia : any = {
            status,
            title,
            link,
            owned,
            wishlist,
            description,
            image
        }

        if (media.media_type === 'show') {
            updatedMedia = {
                ...updatedMedia,
                season,
                episode

            }
        }
        else if (media.media_type === 'game') {
            updatedMedia = {
                ...updatedMedia,
                platform
            }
        }
        else if (media.media_type === 'book' || media.media_type === 'comic') {
            updatedMedia = {
                ...updatedMedia,
                chapter
            }
        }

        updateData({ ...updatedMedia });
        setEditMode(false);
    }
    
    return(
        <span className={styles['edit-popup']}>
            <span className={styles['heading']}>
                <div className={styles['title']}>Edit</div>
                <button 
                    onClick={() => setEditMode(false)}
                    className={styles['close']}
                >
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </span>

            <input 
                type='text' 
                defaultValue={media.title} 
                onChange={(e) => setTitle(e.currentTarget.value)}
                className={styles['media-title']}
            />

            <span>
                <label>
                    <input 
                        type='checkbox' 
                        checked={owned} 
                        onChange={(e) => {
                                    setOwned(e.currentTarget.checked); 
                                    setWishlist(!e.currentTarget.value)
                                }
                            } 
                    />
                    Owned?
                </label>

                <label>
                    <input 
                        type='checkbox' 
                        checked={wishlist} 
                        onChange={(e) => {
                                    setWishlist(e.currentTarget.checked); 
                                    setOwned(!e.currentTarget.value)
                                }
                            } 
                        />
                    Wishlist?
                </label>
            </span>

            <select defaultValue={media.status} onChange={(e) => setStatus(e.currentTarget.value)} >
                <option value="not_started">Not Started</option>
                <option value="in_progress">In Progress</option>
                <option value="finished">Finished</option>
                <option value="completed">Completed</option>
            </select>

            {
                media.media_type === 'show' && (
                    <>
                        <input 
                            type='text' 
                            placeholder='Season' 
                            onChange={(e) => setSeason(parseInt(e.currentTarget.value as any))} 
                            defaultValue={media.season}
                        />
                        <input 
                            type='text' 
                            placeholder='Episode' 
                            onChange={(e) => setEpisode(parseInt(e.currentTarget.value as any))} 
                            defaultValue={media.episode}
                        />
                    </>
                )
            }
            {
                ['book', 'comic'].includes(media.media_type as string) && (
                    <>
                        <input 
                            type='text' 
                            placeholder='Chapter' 
                            onChange={(e) => setChapter(parseInt(e.currentTarget.value as any))} 
                            defaultValue={media.chapter}
                        />
                    </>
                )
            }
            {
                media.media_type === 'game' && (
                    <>
                        <input 
                            type="text" 
                            placeholder='Platform' 
                            onChange={(e) => setPlatform(e.currentTarget.value)} 
                            defaultValue={media.platform}
                        />
                    </>
                )
            }

            <textarea 
                defaultValue={media.description} 
                placeholder='Description'
                onChange={(e) => setDescription(e.currentTarget.value)}
            />
            
            <input 
                type='text' 
                defaultValue={media.image} 
                placeholder='Image URL' 
                onChange={(e) => setImage(e.currentTarget.value)} 
            />
            <input 
                type='text' 
                defaultValue={media.link} 
                placeholder='Link to media' 
                onChange={(e) => setLink(e.currentTarget.value)} 
                />

            <select>
                <option>Add to list</option>
            </select>

            <button onClick={() => update()}>Save Changes</button>
        </span>
    )
}