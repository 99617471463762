import { useState } from "react";
import { ListItemProps } from "../../types/propTypes";
import { DetailCard } from "./DetailCard";
import styles from './ListItem.module.scss';

export function ListItem ({media} : ListItemProps) {
    const filler = 'https://upload.wikimedia.org/wikipedia/en/thumb/7/73/Pikachu_artwork_for_Pok%C3%A9mon_Red_and_Blue.webp/220px-Pikachu_artwork_for_Pok%C3%A9mon_Red_and_Blue.webp.png';
    const [hover, setHover] = useState(false);

    return (
        <span 
            className={styles['list-item']} 
            onMouseOver={() => setHover(true)} 
            onMouseOut={() => setHover(false)}
        >
            <div className={styles['thumbnail']}>
                <img src={media.image || filler} alt={`${media.title}`} />
            </div>
            <DetailCard 
                media={media}
                show={hover}
            />
        </span>
    )
}