import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useSearch } from "../hooks/useSearch";
import { useTimeOfDay } from "../hooks/useTimeOfDay";
import { ListItem } from "./parts/ListItem";
import { MenuBar } from "./parts/MenuBar/MenuBar";
import styles from './Home.module.scss';

export function Home ({media} : any) {
    const timeOfDay = useTimeOfDay();
    const {search, results } = useSearch();
    const session = useAuth();

    useEffect(() => {
        const twoHours = 1000 * 60 * 60 * 2;
        const minute = 1000 * 60 * 60;
        
        const interval = setInterval(() => {
            session();
        }, twoHours + minute);
        
        session();

        return () => clearInterval(interval);
    }, [session]);

    return(
        <>
            <MenuBar media={media} />
            <section className={styles['content']}>
                <h2>Good {timeOfDay}</h2>
                <section>
                    <input 
                        type='text' 
                        placeholder="Search..." 
                        onChange={(e) => search(e.target.value)} 
                    />

                    <p>
                        {!!results ? 
                            `${results.length} result${results.length !== 1 ? 's' : ''}` 
                            : "0 results"
                        }
                    </p>
                    <span style={{
                        display : 'flex',
                        flexDirection :'row',
                        flexWrap : 'wrap'
                    }}>
                        { !!results ? results.map((res : any) => <ListItem media={res}  />) : <></> }
                    </span>
                </section>
            </section>
        </>
    );    
}