import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import styles from './HeartButton.module.scss';
import { HeartButtonProps } from "../../types/propTypes";

export function HeartButton({favorite, updateHeart} : HeartButtonProps) {

    return (
        <span 
            onClick={() => updateHeart({favorite : !favorite})} 
            className={favorite ? styles["favorite"] : styles["not-favorite"]}
        >
            <FontAwesomeIcon icon={faHeart} />
        </span>
    )
}