import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import styles from './LoginPage.module.scss';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    function handleSubmit() {
        console.log(username, password);
        axios({
            method : 'POST',
            url: '/api/login',
            data: {
                username,
                password
            }
        }).then((response) => {
            return response.data;
        }).then((data) => {
            if (data.redirect) {
                navigate(data.redirect_to);
            }
        });
    }

    return(
        <section className={styles['login-screen']}>
            <h1>Uatu: Media Watcher</h1>

            <div className={styles['login-box']}>
                <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>

            <button onClick={() => handleSubmit()}>Log In</button>
        </section>
    );
}

export default Login;