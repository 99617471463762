import { capAndStrip, classStyle } from "../../helpers/capitalize";
import { ChipsProps } from "../../types/propTypes"
import styles from './Chips.module.scss';

export function Chips({ progress, wishlisted, owned } : ChipsProps) {
    
    const Chip = ({text} : {text : string}) => {
        return (
            <div className={[
                    styles['chip'], 
                    styles[classStyle(text)]]
                    .join(' ')}
            >
                {text}
            </div>
        )
    }

    return(
        <span className={styles["chips"]}>
            { 
                progress !== 'not_started' && 
                (<Chip text={capAndStrip(progress)} />) 
            }
            
            { wishlisted && (<Chip text="Wishlisted" />) }
            
            { owned && (<Chip text="Owned" />) }
        </span>
    )
}