import { MediaMessage } from "./MediaMessage";
import { Chips } from "./Chips";
import { Stars } from "./Stars";
import styles from './DetailCard.module.scss';
import { HeartButton } from "./HeartButton";
import { DetailCardProps } from "../../types/propTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateMedia } from "../../store";
import { CardTitle } from "./CardTitle";
import { useEffect, useState } from "react";
import { EditPopup } from "./EditPopup/EditPopup";

export function DetailCard({media, show} : DetailCardProps) {
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();


    const updateData = (newData : any) => {
        fetch('/api/media', {
            method: 'PUT',
            body: JSON.stringify({
                search: {
                    title: media.title,
                    media_type: media.media_type
                },
                update: {
                    ...newData
                }
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then((data) => {
            dispatch(updateMedia({
                title : media.title,
                media_type : media.media_type,
                update : {
                    ...newData
                }
            }));
        });
    }

    useEffect(() => {
        if (!show) setEditMode(show);
    }, [show]);

    return (
        <div className={[
                styles['detail-card'], 
                styles[`${show ? 'show' : 'hide'}`]
            ].join(' ')} 
            id={['card', media.title.replace(' ', '-')].join('-')}
        >
            <span className={styles['card-heading']}>
                <CardTitle title={media.title} />
                <HeartButton favorite={media.favorite} updateHeart={updateData} />
            </span>

            <br />

            <MediaMessage
                type={media.media_type}
                chapter={media.chapter}
                episode={media.episode}
                season={media.season}
                platform={media.platform}
            />

            <Chips
                progress={media.status}
                wishlisted={media.wishlist}
                owned={media.owned}
            />

            <Stars stars={media.rating || 0} updateStars={updateData} />

            <p className={styles["description"]}>
                {media.description}
            </p>

            { editMode && (
                <EditPopup 
                    media={media} 
                    setEditMode={setEditMode}
                    updateData={updateData} 
                />
            )}

            <span className={styles['button-panel']}>
                <button 
                    className={styles['edit-button']}
                    onClick={() => setEditMode(prev => !prev)}
                >
                    <FontAwesomeIcon 
                        icon={faPenToSquare} 
                    />
                </button>
                <a href={media.link} target="_blank" rel="noreferrer" className={styles['play-button']}>
                    <FontAwesomeIcon 
                        className={styles[media.link ? 'active' : 'inactive']} 
                        icon={faArrowUpRightFromSquare} 
                    />
                </a>
            </span>

        </div>
    );
}