import { useState, useEffect } from "react";

export function useTimeOfDay () {
    const [timeOfDay, setTimeOfDay] = useState<string>();
    const [hour, setHour] = useState(new Date().getHours());

    useEffect(() => {
        const interval = setInterval(() => setHour(new Date().getDate()), 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (hour >= 4 && hour <= 12) {
            setTimeOfDay('Morning');
        }
        else if (hour > 12 && hour <=16) {
            setTimeOfDay('Afternoon');
        }
        else if (hour > 16 && hour <= 20) {
            setTimeOfDay('Evening');
        }
        else {
            setTimeOfDay('Night');
        }
    }, [hour]);

    return timeOfDay;
}