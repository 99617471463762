import { capitalize } from "../../helpers/capitalize";
import { MediaMessageProps } from "../../types/propTypes";
import styles from './MediaMessage.module.scss';

export function MediaMessage({
    type, upcoming, releaseDate, chapter, episode, season, series, platform} : MediaMessageProps) {
    
    switch(type) {
        case 'book':
            return (
                <span className={styles['book']}>
                    Ch {chapter || 1}
                </span>
            );
        case 'comic':
            return (
                <span>
                    Ch {chapter || 1}
                </span>
            );
        case 'game':
            return (
                <span className={styles["game"]}>
                    {capitalize(platform || 'N/A')}
                </span>
            )
        case 'movie':
            return (
                <span />
            );
        case 'show':
            return (
                <span className={styles["show"]}>
                    S{season || 1}  E{episode || 1}
                </span>
            )
        default:
            return (<span />)
    }
}