import { useState } from "react";
import { MediaChoice } from "../types/dataTypes";

export function useSearch(media? : MediaChoice) {
    const [results, setResults] = useState([]);

    function search(text : string) {
        fetch(`/api/media?searchTitle=${text}`, {
            method : 'GET'
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setResults(data.media);
        });
    }

    return {search, results};
}