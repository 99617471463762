import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { StarsProps } from "../../types/propTypes";
import styles from './Stars.module.scss';
import { useState } from "react";

export function Stars ({stars, updateStars} : StarsProps) {
    const [hoverIdx, setHoverIdx] = useState(stars);

    const setStars = () => {
        if (hoverIdx === stars) {
            updateStars({ rating : 0});
        }
        else {
            updateStars({rating : hoverIdx});
        }
    }

    return (
        <span className={styles['stars']} onMouseOut={() => setHoverIdx(stars)} >
            {
                [...Array(5)].map(
                    (e : any,i : number) => (
                        <FontAwesomeIcon 
                            icon={faStar} 
                            className={i >= hoverIdx ? styles['gray'] : styles['gold']} 
                            key={i} 
                            onMouseOver={() => setHoverIdx(i+1)}
                            onClick={() => setStars()}
                        />
                    )
                )
            }
        </span>
    );
}