import { configureStore, createSlice } from "@reduxjs/toolkit";
import { Media } from "./types/dataTypes";

const mediaSlice = createSlice({
    name : "media",
    initialState : {
        media : new Array<Media>()
    },
    reducers : {
        saveMedia: (state, action) => {
            state.media = action.payload;
        },
        updateMedia: (state, action) => {
            const { title, media_type, update } = action.payload;
            const idx = state.media.findIndex(obj => (obj.title === title && obj.media_type === media_type));
            state.media[idx] = {
                ...state.media[idx],
                ...update
            }
        }
    }
});

export const { saveMedia, updateMedia } = mediaSlice.actions;

export const store = configureStore({
    reducer : mediaSlice.reducer
});