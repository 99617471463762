import { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router";

export function useAuth() {
    const navigate = useNavigate();

    function checkSession() {
        axios({
            url : '/api/active-session',
            method: 'GET'
        })
        .then((resp) =>{
            return resp.data.active;
        })
        .then((active) => {
            if (!active) navigate('/login');
        })
        .catch(() => {
            return false;
        });
    }

    return checkSession;
}
