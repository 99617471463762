import { Home } from './components/Home';
import { Route, Routes } from 'react-router-dom';
import { ListPage } from './components/ListPage';
import styles from './App.module.scss';
import { MenuBar } from './components/parts/MenuBar/MenuBar';
import AuthRoute from './components/wrappers/AuthRoute';
import Login from './components/LoginPage';

function App() {

  const media = [
    'Home',
    'Movies',
    'Shows',
    'Comics',
    'Books',
    'Games'
  ]

  return (
    <div className={styles['App']}>
      <section>
        <Routes>
          <Route path='/login' element={<Login />} />
          
          <Route path='/' element={<Home media={media} />} />
          <Route path='/books' element={<ListPage mediaTypes={media} type='book'/>} />
          <Route path='/shows' element={<ListPage mediaTypes={media} type='show' />} />
          <Route path='/comics' element={<ListPage mediaTypes={media} type='comic' />} />
          <Route path='/movies' element={<ListPage mediaTypes={media} type='movie' />} />
          <Route path='/games' element={<ListPage mediaTypes={media} type='game' />} />
        </Routes>
      </section>
    </div>
  );
}

export default App;
