import { Link, useLocation } from 'react-router-dom';
import styles from './MenuBar.module.scss';



interface MenuBarProps {
    media : string[]
}

export function MenuBar({ media } : MenuBarProps) {
    const location = useLocation();

    const locationMatch = (label : string) => {
        if (label === 'Home') {
            return location.pathname === '/';
        }
        return location.pathname.replace('/', '') === label.toLowerCase()
    }

    return (
        <section className={styles["menu-bar"]}>
            <div className={styles["logo"]}>LOGO HERE</div>

            <div className={styles["links"]}>
                {media.map((label) => (
                    <Link
                        className={[
                            styles["link"],
                            styles[locationMatch(label) ? "selected" : ""],
                        ].join(" ")}
                        to={label !== "Home" ? `/${label.toLowerCase()}` : "/"}
                        key={label}
                    >
                        {label}
                    </Link>
                ))}
            </div>
        </section>
    );
}