import { useBackendData } from "../hooks/useBackendData";
import { MediaChoice } from "../types/dataTypes";
import { LoadingList } from "./parts/LoadingList";
import styles from './ListPage.module.scss';
import { capitalize } from "../helpers/capitalize";
import { useEffect, useState } from "react";
import { Sidebar } from "./parts/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { MenuBar } from "./parts/MenuBar/MenuBar";
import { useAuth } from "../hooks/useAuth";

export function ListPage ({ type, mediaTypes } : {type : MediaChoice, mediaTypes : string[]}) {
    const loading = useBackendData(type);
    const [sidebar, showSidebar] = useState(false);
    const content = useSelector((store : any) => store.media);
    const session = useAuth();

    const toggleSidebar = () => {
        showSidebar(prev => !prev);
    }

    useEffect(() => {
        const twoHours = 1000 * 60 * 60 * 2;
        const minute = 1000 * 60 * 60;
        
        const interval = setInterval(() => {
            session();
        }, twoHours + minute);
        
        session();

        return () => clearInterval(interval);
    }, [session]);

    return (
        <>
            <MenuBar media={mediaTypes} />

            <section className={styles['content']}>
                <h1 className={styles["media-title"]}>{capitalize(type)}s</h1>

                <span style={{
                    display : 'flex'
                }}
                className={styles['button-container']}
                >
                    <button className={styles['add-button']} onClick={() => toggleSidebar()}>
                        <FontAwesomeIcon icon={faPlus} /> Add Media
                    </button>
                </span>

                <section className={styles['content-list']}>
                    {
                        <LoadingList 
                            length={content.length}
                            loading={loading}
                            content={content}
                            type={type}
                        />
                    }
                </section>

                <Sidebar show={sidebar} toggle={toggleSidebar}  />
            </section>
        </>
    );
}