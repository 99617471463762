import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveMedia } from "../store";
import { MediaChoice } from "../types/dataTypes";
import { useNavigate } from "react-router-dom";

export function useBackendData (type : MediaChoice) {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`/api/media/${type}`, {
            method : 'GET'
        }).then((resp) => {
            return resp.json()
        }).then((data) => {
            if (data.redirect) navigate(data.redirect_to);
            else {
                dispatch(saveMedia(data.media));
                setLoading(false);
            }
        }).finally(() => {
            setLoading(false)
        });
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    
    return loading;
}