import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { capitalize } from '../../helpers/capitalize';
import styles from './Sidebar.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { saveMedia } from '../../store';

export function Sidebar({show, toggle} : {show : boolean, toggle : () => void}) {
    const [media_type, setMedia] = useState<string>("show");
    const [status, setStatus] = useState<string>("not_started");

    const [image, setImage] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [link, setLink] = useState<string>();

    const [favorite, setFavorite] = useState(false);
    const [owned, setOwned] = useState(false);
    const [wishlist, setWishlist] = useState(false);

    const [season, setSeason] = useState<number>();
    const [episode, setEpisode] = useState<number>();
    const [chapter, setChapter] = useState<number>();

    const [platform, setPlatform] = useState<string>();

    const content = useSelector((state : any) => state.media);
    const dispatch = useDispatch();

    async function submitNew() {

        let savedMedia : any = {
            media_type,
            image,
            title,
            status,
            favorite,
            wishlist,
            owned,
            link
        };

        if (media_type === 'show') {
            savedMedia = {
                ...savedMedia,
                season,
                episode

            }
        }
        else if (media_type === 'game') {
            savedMedia = {
                ...savedMedia,
                platform
            }
        }
        else if (media_type === 'book' || media_type === 'comic') {
            savedMedia = {
                ...savedMedia,
                chapter
            }
        }

        const response = await fetch('/api/media', {
            method: 'POST',
            body: JSON.stringify(savedMedia),
            headers : {
                'Content-Type' : 'application/json'
            }
        });

        if (response.status === 200) {
            const updated = await response.json();
            console.log(content, updated);
            dispatch(saveMedia([...content, updated]));
            toggle();
        }
        else {
            alert('Failed...');
        }
    }

    return (
        <div className={[styles['add-sidebar'], styles[show ? 'open' : 'close']].join(' ')}>
            <span className={styles['title']}>
                <span className={styles['heading']}>Add Media</span>
                <button className={styles['button']} onClick={() => toggle()}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
            </span>

            <input type='text' placeholder='Title' onChange={(e) => setTitle(e.currentTarget.value)} />
            <select onChange={(e) => setMedia(e.currentTarget.value)}>
                <option value="show">Show</option>
                <option value="book">Book</option>
                <option value="movie">Movie</option>
                <option value="comic">Comic</option>
                <option value="game">Game</option>
            </select>

            <input type='text' placeholder='Image URL' onChange={(e) => setImage(e.currentTarget.value)} />
            
            <span>
                <label>
                    <input type='checkbox' onChange={(e) => setFavorite(e.currentTarget.checked)} />
                    Favorite?
                </label>

                <label>
                    <input type='checkbox' checked={owned} onChange={(e) => {setOwned(e.currentTarget.checked); setWishlist(!e.currentTarget.value)}} />
                    Owned?
                </label>

                <label>
                    <input type='checkbox' checked={wishlist} onChange={(e) => {setWishlist(e.currentTarget.checked); setOwned(!e.currentTarget.value)}} />
                    Wishlist?
                </label>
            </span>

            <select onChange={(e) => setStatus(e.currentTarget.value)}>
                <option value="not_started">Not Started</option>
                <option value="in_progress">In Progress</option>
                <option value="finished">Finished</option>
                <option value="completed">Completed</option>
            </select>

            <input type='date' placeholder='Release Date' />

            {
                media_type === 'show' && (
                    <>
                        <input type='text' placeholder='Season' onChange={(e) => setSeason(parseInt(e.currentTarget.value as any))} />
                        <input type='text' placeholder='Episode' onChange={(e) => setEpisode(parseInt(e.currentTarget.value as any))} />
                    </>
                )
            }
            {
                ['book', 'comic'].includes(media_type) && (
                    <>
                        <input type='text' placeholder='Chapter' onChange={(e) => setChapter(parseInt(e.currentTarget.value as any))} />
                    </>
                )
            }
            {
                media_type === 'game' && (
                    <>
                        <input type="text" placeholder='Platform' onChange={(e) => setPlatform(e.currentTarget.value)} />
                    </>
                )
            }

            <input type='text' placeholder='Link to media' onChange={(e) => setLink(e.currentTarget.value)} />

            <button className={styles['submit-button']} onClick={() => submitNew()}>
                Add {capitalize(media_type)}
            </button>
        </div>  
    );
}